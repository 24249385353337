/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
 import React, { useState,useRef } from 'react';
 import { getImage, GatsbyImage } from 'gatsby-plugin-image';
 import tw, {css} from 'twin.macro'
 import { A } from './styles'

const Layout = (props) => {
  const {node,lg,category,cl,dataAosDuration,imgH=''} = props
  const [tipBool,setTipBool] = useState(false)
  const refE = useRef(null);
  const mouseFun = (e)=>{
    let E = refE.current.querySelector("span").style
      E.left = e.nativeEvent.offsetX+'px'
      E.top = e.nativeEvent.offsetY+"px"
  }

  const styles = {
    spanE: [
      tw`bg-white border-2 border-black z-[1] transform min-w-min max-w-max w-full p-4 absolute`,
      tipBool ? tw`block`:tw`hidden`,
    ],
    figureE:[
      tw`w-full relative h-[180px]`,
      tw`after:content-none after:w-full after:h-full after:bg-transparent after:absolute after:left-0 after:top-0 after:z-[1]`,
      css`cursor:none`
    ]
  }
  return (
    <div data-aos="fade-up" data-aos-duration={dataAosDuration} className={`w-full flex flex-col items-start h-full text-xl cursor-pointer relative hover:z-[1] ${cl?cl:''}`} onClick={()=>{window.location.href=node.link.url}}>
        <figure ref={refE} css={styles.figureE} className={`mb-4 ${imgH}`} onMouseOver={()=>{setTipBool(true)}} onMouseLeave={()=>{setTipBool(false)}} onMouseMove={(e)=>{mouseFun(e)}}>
          <span css={styles.spanE}>{node.tooltip?node.tooltip:"查看更多"}</span>
          <GatsbyImage className="bg-light w-full max-w-full h-full overflow-hidden object-cover absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]" image={getImage(node.image?.localFile||node.seo?.shareImage?.localFile)} alt="banner"/>
        </figure>
          {
            node.date?<p className="py-4">{node.stories_category?.title || category} – {node.date}</p>:null
          }
          {
            lg?<a href={node.link.url} className='text-[32px] leading-tight sm:text-[28px]'>{node.title}</a>:
            <A variant='border-gray' href={node.link.url} className='text-xl leading-tight'>{node.title}</A>
          }
          {
            node.sub_title?<p className="pt-2.5">{node.sub_title}</p>:null
          }
          {
            lg?<p className="pt-4"><A variant="border-gray" href={node.link.url}>Read more</A></p>:null
          }
    </div>
  )
}


export default Layout
