import React, { useState,useRef } from 'react';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import { A } from '../components/styles'
import Group from "../components/group"

export default function IndexPage({ pageContext: { node,list },location }) {
    const currIndex = list.node.stories_details.findIndex((n)=>{
        return n.url == node.link.url
    })
    return (
        <Layout>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'gemfisher',
              }}
            />
            <div className="pt-[260px] pb-[200px] lg:pt-[165px] lg:pb-[120px]">
              <div className="grid grid-cols-12 text-[72px] xl:text-[56px] lg:text-[32px] leading-tight lg:grid-cols-1">
                  <div className="col-span-3">{node.stories_category.title}</div>
                  <h1 className="col-span-9">{node.title}</h1>
              </div>
              <figure className="mt-20">
                <GatsbyImage className="w-full" image={getImage(node.media.localFile)} alt="banner"/>
              </figure>
              <div className="grid grid-cols-12 gap-5 pt-8 text-xl pb-[200px] lg:pb-[120px] sm:grid-cols-1">
                <div className="col-span-3"><span className="text-gray pr-[15px]">Date</span> {node.date}</div>
                <div className='col-span-9'>
                    <span className="text-gray pr-[15px]">In</span> <A variant="border-gray" href={node.stories_category.link.url}>{node.stories_category.title}</A>
                </div>
              </div>
              <article className="pb-[200px] lg:pb-[120px]" dangerouslySetInnerHTML={{__html:node.content}}/>
              <div className="flex items-center pt-14 pb-[200px] lg:pb-[120px] border-t border-black lg:flex-col lg:items-start">
                <div className="text-[56px] leading-tight lg:pb-14 xl:text-[40px] sm:text-[32px]">Share on</div>
                <ul className="grid grid-cols-4 gap-14 ml-auto text-xl lg:ml-0 md:grid-cols-3 sm:grid-cols-2">
                  <li><A variant="link" href="#">LinkedIn</A></li>
                  <li><A variant="link" href="#">Facebook</A></li>
                  <li><A variant="link" href="#">Twitter</A></li>
                  <li><A variant="link" href="#">E-mail</A></li>
                </ul>
              </div>
              <div className="border-t border-black pt-14">
                <div className="text-[40px] leading-none pb-14 xl:text-[40px] sm:text-[32px]">Related</div>
                <div className="grid grid-cols-4 gap-8 pb-[95px] lg:grid-cols-2 sm:grid-cols-1">
                {
                    list.node.stories_details.map((n,i,a)=>{
                      if((i > currIndex && i < currIndex+4) || ((currIndex > a.length-4) && i < 3-(a.length-currIndex-1))){
                        return (
                            <Group category={node.stories_category.title} lg={true} key={i} node={n}/>
                        )
                      }
                    })
                }
                </div>
                <A href="/stories" variant="more">Show more stories</A>
              </div>
            </div>
        </Layout>
    )
}

